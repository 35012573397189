        .list_main {
            height: auto;
            width: 95%;
            padding-bottom: 15px;
            padding-top: 10px;
            position: relative;
            border:#949494 solid 1px;
            border-radius: 6px;
            background-color: white;
            margin-bottom: 30px;
            margin-left: 2.5%;
            box-shadow: 1px 1px 3px 1px rgb(216, 220, 220) ;
            margin-right: 2.5%;
          
        }
        .filterbutton_main{
       margin-bottom: 40px;
       margin-left: 5%;
       margin-top: 25px;
       display: flex;
       justify-content: center;
      
      
        }
        
        .image_div {
            height: auto;
            width: 95%;
            margin-left: 2.5%;
            margin-right: 2.5%;
            text-align: center;
            border: 1px solid;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 10px;
           
        }
        
        .image_div img {
            height: 100%;
            width: 100%;
            
        }
        
        .heart {
            color: rgb(85, 56, 56);
            height: 30px;
            width: 30px;
        }
        
        .share {
            margin-left: 80%;
            height: 20px;
            width: 20px;
        }
        .button_div{
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 100;
          
        }
        .button_div button:nth-child(1) {
           width: 50%;
            background-color: #2b3395;
            color: aliceblue;
            padding-top: 5px;
            padding-bottom: 5px;
            border-radius: 6px;
            border: none;
        }
        
        .button_div button:nth-child(2) {
            padding-top: 5px;
            padding-bottom: 5px;
            background-color: #ec383b;
            width: 50%;
            color: aliceblue;
            border-radius: 6px;
            border: none;
           

        }
        
        @media (width<450px) {
            .filter_main {
                height: 100%;
                background-color: white;
                position: absolute;
                top: 0px;
                margin-left: 3%;
                overflow: scroll;
                width: 80%;
            }
        }
        
        @media (width>450px) {
            .filter_main {
                width: 380px;
                height: 100%;
                background-color: white;
                position: absolute;
                top: 0px;
                overflow: scroll;
            }
        }
        
        @media (width>450px) {
            .body_main {
                width: 450px;
                background-color:rgb(247, 247, 247);
                }
        }
        
        @media (width<450px) {
            .body_main {
                width: 100%;
              
               background-color:rgb(247, 247, 247);
               
            }
        }
        
        .filter_main button {
            border: 0.1em solid;
            font-weight: 300;
           
        }
        
        .filter_main>div {
            padding-bottom: 20px;
            padding-top: 20px;
        }
        
        .filterby_models {
            height: 300px;
            overflow: scroll;
            padding-left: 10px;
            margin-top: 10px;
        }
        
        .filterby_models input {
            height: 30px;
            width: 30px;
        }
        
        .range_select {
            font-size: 20px;
            padding-left: 10px;
        }
        
        .range_select button {
            border-radius: 20px;
            margin-right: 20px;
            margin-top: 10px;
            font-size: 15px;
        }
        
        .truevalue_certified {
            padding-left: 10px;
            margin-top: 10px;
            font-size: 16px;
            padding-top: 10px;
        }
        
        .truevalue_certified input {
            height: 30px;
            width: 30px;
            margin-right: 10px;
            background-color: antiquewhite;
        }
        
        .truevalue_certified span {
            margin-left: 2px;
            padding-top: 20px;
        }
        
        .select_budget {
            padding-left: 10px;
            font-size: 20px;
            padding-top: 10px;
            font-size: 20px;
        }
        
        .select_budget button {
            border-radius: 20px;
            margin-right: 10px;
            margin-top: 10px;
            font-size: 15px;
        }
        
        .body_type {
            padding-left: 10px;
            font-size: 20px;
            padding-top: 10px;
            font-size: 20px;
        }
        
        .body_type button {
            border-radius: 20px;
            margin-right: 10px;
            margin-top: 10px;
            font-size: 15px;
        }
        
        .filterbybrand {
            margin-top: 20px;
            padding-left: 10px;
            margin-bottom: 10px;
            font-size: 20px;
        }
        
        .age_select {
            padding-left: 10px;
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 20px;
        }
        
        .age_select button {
            margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            font-size: 15px;
        }
        
        .owner {
            margin-left: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 20px;
        }
        
        .owner input {
            height: 30px;
            width: 30px;
        }
        
        .fueltype {
            margin-top: 20px;
            margin-left: 10px;
            font-size: 20px;
        }
        
        .fueltype input {
            height: 30px;
            width: 30px;
        }
        
        .transmission {
            margin-top: 10px;
            margin-left: 10px;
            font-size: 20px;
        }
        
        .transmission input {
            height: 30px;
            width: 30px;
        }
        
        .vehicleName {
            font-size: 16px;
            font-weight: 800;
        }
        
        .details_div div {
            margin-left: 10px;
            margin-top: 3px;
            font-family: "Roboto", sans-serif;
        }
        
        .details_div input {
            margin-right: 02px;
        }
        
        .details_div button {
            margin-right: 02%;
            width: 45%;
        }
        .calculateEmi{
            display: flex;
            justify-content: space-between;
            color: #2b3395;
            font-weight: 600;
            font-family: "Roboto", sans-serif;
            margin-bottom: 10px;
           
        }
        .calculateEmi button {
            font-size: 12px;
            
            width:35%;
            border:#949494 solid 1px;
            background-color: white;
            border-radius: 6px;
            color: #0c0c0c;
            padding-top: 4px;
            padding-bottom: 4px;
            font-weight: 700;
            font-family: "Roboto", sans-serif;
            
        }
         .reset_div {
            display: flex;
        }
        
        .reset_div div:nth-child(2) {
            margin-left: auto;
            margin-right: 10%;
            font-weight: 500;
            color: #2b3395;
        }
        
        .reset_div div:nth-child(1) {
            font-weight: 800;
        }
        
        .close_button {
            margin-left: 85%;
            margin-right: 10%;
            width: 25px;
            height: 25px;
            margin-top: 10px;
            cursor: grabbing;
        }
        
        .filter_button {
            height: 37px;
            width: 50%;
            background-color:white;
            color: #2b3395;
            border:#949494 solid 1px;
        }
        .sortby_button{
            height: 37px;
            width: 50%;
            margin-right: 5%;
            background-color:white;
            color: #2b3395;
            border:#949494 solid 1px;
            border-radius: 2px;
           
        }
        .header_main{
          height: auto;
          width: auto;
          padding-top: 15px;
         
          background-color: white;
         
        }
        .header_main>div{
            display: flex;
            justify-content: left;
            /* margin-top: 20px; */
            margin-bottom: 10px;
            
           }
           .header_main>div:nth-child(2){
            margin-top: 20px;
        
            
           }
         .header_main>div:nth-child(2)>div:nth-child(1){
           margin-left: 10px;
          
         }
         .header_main>div:nth-child(2)>div:nth-child(2){
           width: 100%;
          
         }
         .header_main>div:nth-child(2)>div:nth-child(2) input{
            width: 100%;
            border:none;
            font-size: 14px;
            font-weight: 900;
            height: auto;
            background-color: white;
           
          }
         .header_main>div:nth-child(2)>div:nth-child(3){
            margin-right: 5px;
            
            
          }
        .header_main>div:nth-child(1)>div:nth-child(2){
            margin-left: 26%;
           }
        .header_main>div:nth-child(1)>div:nth-child(3){
            margin-left: 1%;
            padding-top: 1%;
            font-weight: 700;
            font-size: 15px;
        }
        .header_main div span{
            font-weight: 500;
        }
        .header_main>div:nth-child(1)>div:nth-child(4){
            margin-left: 1%; 
           
        }
        .header_main>div:nth-child(1)>div:nth-child(5){
            margin-left: 5%;
        }
        .truevalue_image {
            height: 90%;
            width: 100%;
            margin-left: 10px;
        }
         .globe_icon{
             height: 12px;
             width: auto;
             margin-top: 5px;
         }
         .person_logo{
            height: 18px;
            width: auto;
            margin-top: 5px;
            margin-right: 25px;
            color: #2b3395;
            background-color: rgb(192, 191, 191);
            border-radius: 5px;
         }
         .down_arrow{
            height: 25px;
            margin-top: 2px;
            width: auto;
            color: #2b3395;
           
         }
         .down_arrow1{
            height: 25px;
            width: auto;
            color: #2b3395;
            margin-right: 15px;
         }
         .line{
             margin-right: 3%;
             margin-left: 3%;
             border: #2b3395 solid 1px;
         }
         .versions_main{
             height: auto;
             position: relative;
             /* padding-right: 5px; */
             background-color: rgba(242,242,242);
         }
         .version_button_helloar{
         width:100% ;
         height: auto;
         background-color: rgb(9, 48, 89);
         color: rgb(250, 250, 250);
         margin-top: 10px;
         margin-bottom: 10px;
     
     }
         .version_button_truevalue{
            width:100% ;
            height: auto;
            background-color: #57ca85;
            color: black;
            margin: auto;
            margin-top: 10px;
            margin-bottom: 10px;
            
           
     }
     .version_div{
         width: 100%; 
     }

     @media (width<450px) {
        .preview_main {
          height: auto;
          width: 100%;
        }
    }
    @media (width>450px) {
        .preview_main {
          height: auto;
          width: 450px;
        }
    }

    .preview_main img{
        height: 100%;
        width: 100%;
    }
    .home_bar{
        height: 70px;
        width: 100%;
        background-color: #2b3395;
        color: aliceblue;
        text-align: center;
        padding-top: 10px;
        font-size: 8px;
        padding-bottom: 10px;
        display: flex;
        justify-content: left;
        margin-bottom: 20px;
    }
   .home_bar span{
       margin-left: 10px;
       margin-top: 12px;
       font-weight: 200;
       font-size: 15px;
   }
   .home_bar>span:nth-child(6){
       font-weight: 700;
   }
   .left_arrow{
       width: 30px;
       height: auto;
       margin-left: 20px;
   }
   .preview_price{
       display: flex;
       justify-content: space-between;
       font-size: 20px;
       font-weight: 500;
       padding-left: 20px;
       padding-right: 20px;
       margin-top: 20px;
   }
   .preview1_price{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 500;
    padding-left: 5px;
    padding-right: 20px;
    margin-top: 20px;
    margin-left: 10px;
}
   .preview_details{
       padding-top: 20px;
       margin-left: 10px;
       font-size: 15px;
       font-weight: 600;
     
   }
   .preview_details1{
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    
}
.preview_details1>div:nth-child(1){
display: flex;
margin-top: 15px;
justify-content: space-between;

}
.preview_details span{
    margin-left: 5px;
}

.preview_details1 span{
    margin-left: 5px;
    font-size: 12px;
       font-weight: 400;

}
.preview_details1 span:nth-child(2){
  
    font-size: 12px;
       font-weight: 600;
}
.preview_details1 button{
  
     margin-bottom: 15px;
     font-weight: 100;
     font-size: 11px;
     margin-right: 5%;
     border-radius: 6px;
     border:#949494 solid 1px;
     padding-left: 9px;
     padding-right: 9px;
     padding-top: 6px;
     padding-bottom: 5px;
  
 
     
 }
.preview_details button{
   margin-left: 20px;
    margin-bottom: 30px;
    font-weight: 100;
    font-size: 13px;
}
.preview_buttons button{
background-color: #ec383b;
color: aliceblue;
width: 100%;
margin-top: 20px;
height: 40px;
border: none;
}
.preview_buttons button:nth-child(2){
    background-color: #2b3395;
    color: aliceblue;
   }

.preview_main iframe{
 width: 100%;
 }
 .preview_image_div{
     height: auto;
     width: 100%;
     position: relative;
     background-color: #0c0c0c;
     text-align: center;
     
 }
    .iframe_main{
        margin: 0px;
        position: fixed;
        height: calc(100% - 48px);
        width:100vw;
        max-width: 450px;
    }
     .iframe_main iframe{
        width: 100%;
        height: 100%;
     }
     .preview_innerdiv{
         height: 150px;
         width: 100%;
         position: absolute;
         top: 50%;
         display: flex;
         color: aliceblue;
         justify-content: center;
         text-align: center;
     }
     .preview_innerdiv button{
      height: auto;
      width: auto;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: rgb(47, 47, 47);
      color: white;
      border: none;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 300;
      position: absolute;
      bottom:20px;
      background-color: rgba(47, 47, 47, 0.5);
     }
     .home_bar1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        width: 100%;
        color: rgb(255, 255, 255);
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #2b3395;
        padding-left: 20px;
    }
    .home_bar1 span{
        margin-left: 10px;
        margin-top: 7px;
        font-weight: 300;
        font-size: 12px;
      
    }
    .left_arrow1{
        width: 25px;
        height: auto;
         margin-top: 3px;
    }
     
    .cta_button_container button{
        background-color: red;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 20px;
        padding: 5px 15px;
        margin-right: 10px;
        font-weight: 700;
        font-size: 12px;
    }